$(function() {
    $('.js-inview').aemInview({
        addClassName: 'active'
    })    
});


$(function(){
    let timerId = null
    const DELAY:number = 500
    const $MENU = $('.js-menu-wrapper')
    const PASSIVE_CLASS = "inactive"

    $(window).on('scroll', ()=> {
        if(timerId) {
            clearTimeout(timerId)
        }


        $MENU.addClass(PASSIVE_CLASS)

        timerId = setTimeout(()=> {
            $MENU.removeClass(PASSIVE_CLASS)
        }, DELAY)
    })
});